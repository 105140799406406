import React, { useEffect, useMemo, useState } from 'react'
import { PrintElement, consts, groupBy } from '../Utlities/functions';
import BModel from '../components/BModel';
import { Formik } from 'formik';
import FormInput from './../components/FormInput';
import * as Yup from "yup"
import FormTextArea from '../components/FormTextArea';
import Header from '../components/Header';
import $ from "jquery"
import JustSelect from './../components/JustSelect';
import LoadingSpinner from '../components/LoadingSpinner';
import FormCheckBox from './../components/FormCheckBox';
import { useRecoilValue } from 'recoil';
import { getText, languageState, pickText } from '../Utlities/app-store';
import { Alert, Hint } from 'fnon';
import FormSelect from '../components/FormSelect';
import FooterInfo from '../components/FooterInfo';
import Report from '../components/Report';
import "./clientpage.css"
// import { Printd } from 'printd'
import LanguageChanger from '../components/LanguageChanger';
import { Link } from 'react-router-dom';

function filterTexts(id) {
    const f = consts.filters.find(c => c.Id === id);
    if (f) {
        return pickText(f.Ar, f.En)
    }
    return "";
}

const ItemCard = React.memo(({ index, item, onImageClicked, onRequest, ...etc }) => {
    const { Job, Country, Photo, Religion, Name, Category, Experiance } = item;
    return (
        <div className="col-xl-4 col-md-6 col-sm-12">
            <div className="card position-relative overflow-hidden adel">
                <div className="card-content">
                    <div className="card-body p-1 small">
                        <table className='table-bordered w-100 same-width small text-center reveal'>
                            <tbody>
                                <tr><td colSpan={6} className='card-title text-primary'>{Name}</td></tr>
                                <tr><th>{getText('type1')}</th><td colSpan={5}>{filterTexts(Job)}</td></tr>
                                <tr><th>{getText('type2')}</th><td colSpan={2}>{filterTexts(Category)}</td><th>{getText('type0')}</th><td colSpan={2}>{filterTexts(Country)}</td></tr>
                                <tr><th>{getText('type4')}</th><td colSpan={2}>{filterTexts(Religion)}</td><th>{getText('type3')}</th><td colSpan={2}>{filterTexts(Experiance)}</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <img src={`/content/${Photo}`} onClick={onImageClicked} className="card-img-top img-fluid hover-effect scale" />
                    <div className="btn-group btn-group-sm w-100">
                        <a className="btn btn-success" target='_blank' href="https://wa.me/+966135754000">{getText('whatsapp')}</a>
                        <div className="btn btn-primary" onClick={onRequest}>{getText('requestnow')}</div>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div className={`mt-4 mb-4 col-lg-4 col-md-6`} {...etc}>
            <div className="card border overflow-hidden z-index-2" style={{ height: "450px" }}>
                <img src={`/content/${Photo}`} data-source height={440} className="card-img-top hover-effect" style={{ objectFit: "cover", position: "absolute", "top": 0, left: 0, bottom: 0, right: 0 }} onClick={onImageClicked}></img>
                <div className="card-body d-flex flex-column justify-content-end z-index-1 px-0" style={{ pointerEvents: "none" }}>
                    <div className="row bg-white p-1 px-2">
                        <div className="col-12 text-center">
                            {filterTexts(Job)}
                        </div>
                        <hr className="dark horizontal m-0 my-1" />
                        <div className="col-6">
                            <i className="fa-solid fa-earth-americas"></i>
                            <span className="mb-0 text-sm mx-3">{filterTexts(Country)}</span>
                        </div>
                        <div className="col-6">
                            <i className="fa-solid fa-star-and-crescent"></i>
                            <span className="mb-0 text-sm mx-3">{filterTexts(Religion)}</span>
                        </div>
                    </div>
                </div>
                <div className="card-header p-0 position-relative mt-n4 z-index-2 bg-white">
                    <div className={`border-radius-lg  text-white text-center d-flex`}>
                        <a href='https://wa.me/+966135756111' onClick={e => e.stopPropagation()} target="_blank" className='text-white hover-effect scale w-50 d-flex py-1 justify-content-center align-items-center bg-gradient-success'><i className="fa-brands fa-whatsapp fs-4 mr-6"></i> {getText("whatsapp")}</a>
                        <span className='border'></span>
                        <span className={`hover-effect scale w-50 d-flex py-1 justify-content-center align-items-center bg-info`} onClick={onRequest}><i className="fa-regular fa-handshake fs-4 mr-6"></i>{getText("requestnow")}</span>
                    </div>
                </div>
            </div>
        </div>
    )
})


function NewClientPage(params) {
    const lng = useRecoilValue(languageState)
    const [people, setPeople] = useState(null);
    const [order, setOrder] = useState(null);
    const [filters, setFilters] = useState([]);
    const [img, setImg] = useState(null);
    function handleFilter() {
        const fs = $('.row.filter select[data-target]').filter((a, b) => b.value !== "");
        if (fs.length > 0) {
            $('.btn-clear').removeClass('disabled')
            const _filters = fs.toArray().reduce((ac, i) => {
                if (!ac) ac = {};
                var attr = $(i).attr('data-target');
                attr = attr == 0 ? "Country" : attr == 1 ? "Job" : attr == 2 ? "Category" : attr == 3 ? "Experiance" : "Religion";
                ac[attr] = i.value;
                return ac;
            }, {});
            let data = consts.dashboard;
            Object.keys(_filters).forEach(key => {
                data = data.filter(c => c[key] == _filters[key]);
            })
            setPeople(data);
        } else {
            setPeople(consts.dashboard)
        }
    }
    const filtersHtml = useMemo(() => {
        if (filters.length == 0) return null;
        return (<div className="row filter py-3">
            {filters.map(group => <div className="col-lg-2 col-md-3" key={group.key}><JustSelect key={group.key} data-target={group.key}>
                <option value="">{getText("type" + group.key)}</option>
                {group.value.map(item => <option value={item.Id} key={item.Id}>{pickText(item.Ar, item.En)}</option>)}
            </JustSelect></div>)}
            <div className='col-12 col-lg-2 col-md-3 text-center'><span className="btn-group" style={{ direction: "ltr" }}><span className="btn btn-warning" onClick={handleFilter}>{getText("search")}</span><span className="btn btn-danger btn-clear disabled" onClick={(e) => {
                if (e.currentTarget.classList.contains('disabled')) return;
                $(e.currentTarget).addClass('disabled');
                $('.row.filter select[data-target]').val("");
                handleFilter();
            }}><i className="fa-solid fa-broom" style={{ pointerEvents: "none" }}></i></span></span></div>
        </div>)
    }, [lng, filters])
    function getData() {
        if (consts.dashboard.length > 0) {
            setPeople(consts.dashboard)
        } else {
            $.ajax("/api/persons/getdata").then(data => {
                consts.dashboard = data;
                setPeople(data);
            }).catch(() => setPeople([]));
        }
    }
    useEffect(() => {
        if (!consts.filters) {
            $.ajax("/api/filters/getdata").then(data => {
                consts.filters = data;
                setFilters(groupBy(data.filter(c => c.Type !== 5), "Type"));
                getData();
            })
        } else {
            getData();
        }

        // add event listener to scroll
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])
    return (
        <>

            <div id="main" className="layout-horizontal rtl" style={{ backgroundImage: "url(/content/image.jpg)" }}>
                <header className="">
                    <div className="header-top">
                        <div className="container">
                            <div className="logo">
                                <LanguageChanger className="float-start mx-2" />
                                {getText('title')}
                            </div>
                            <div className="header-top-right h-logos">
                                <img src='/content/musaned-logo.png' />
                                <img src='/content/hr-logo.png' />
                            </div>
                        </div>
                    </div>
                </header>
                <div className="bg-primary">
                    <div className="container">
                        <div className=''>
                            {filtersHtml}
                        </div>
                    </div>
                </div>
                {/* <div data-spacer></div> */}

                {/* <section className='d-flex justify-content-center align-items-center flex-column mt-3 bg-img' style={{ position: "sticky", top: 0, backgroundImage: 'url(/content/sticky.jpg)', backgroundPosition: "center top", backgroundRepeat: "no-repeat", backgroundColor: "#f2f7ff", minHeight: "90vh", marginBottom: "-70vh" }}>

</section> */}

                <div className='container mx-auto'>
                    {/* <MainSwiper /> */}
                    <div className="">
                        <div className="row">
                            {people === null ? <LoadingSpinner title={getText("pleasewait")} /> : people?.length > 0 ? people.map((item, index) => <ItemCard
                                key={item.Id}
                                index={index}
                                item={item}
                                onImageClicked={() => {
                                    setImg("/content/" + item.Photo);
                                }}
                                onRequest={() => {
                                    setOrder(item)
                                }}
                            />) : null}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {order && <Request order={order} setOrder={setOrder} callback={(updated) => {
                if (updated) {
                    handleFilter();
                }
            }} />}
            <div className="floting-whatsapp"><a href="https://wa.me/+966135754000" target="_blank" className="hover-effect scale bg-success text-white"><i className="fa-brands fa-whatsapp fs-4"></i></a></div>
            <ImgPreview img={img} setImg={setImg} />
        </>
    )
}
const handleScroll = () => {
    const verticalScroll = window.scrollY || document.documentElement.scrollTop;
    if (verticalScroll < 400) {
        // Run something when scroll is more than 400px
        $(".btn.fade-out").show('fast')
        // Add your logic or function call here
    } else {
        $(".btn.fade-out").hide('fast')
    }
}
export default NewClientPage;



const Old = () => {
    const lng = useRecoilValue(languageState)
    const [order, setOrder] = useState(null);
    const [img, setImg] = useState(null);
    const [filters, setFilters] = useState([]);
    const [people, setPeople] = useState(null);
    const getData = () => {
        if (consts.dashboard.length > 0) {
            setPeople(consts.dashboard)
        } else {
            $.ajax("/api/persons/getdata").then(data => {
                consts.dashboard = data;
                setPeople(data);
            }).catch(() => setPeople([]));
        }
    }
    useEffect(() => {
        if (!consts.filters) {
            $.ajax("/api/filters/getdata").then(data => {
                consts.filters = data;
                setFilters(groupBy(data.filter(c => c.Type !== 5), "Type"));
                getData();
            })
        } else {
            getData();
        }
    }, [])
    function handleFilter() {
        const fs = $('.row.filter select[data-target]').filter((a, b) => b.value !== "");
        if (fs.length > 0) {
            const _filters = fs.toArray().reduce((ac, i) => {
                if (!ac) ac = {};
                var attr = $(i).attr('data-target');
                attr = attr == 0 ? "Country" : attr == 1 ? "Job" : attr == 2 ? "Category" : attr == 3 ? "Experiance" : "Religion";
                ac[attr] = i.value;
                return ac;
            }, {});
            let data = consts.dashboard;
            Object.keys(_filters).forEach(key => {
                data = data.filter(c => c[key] == _filters[key]);
            })
            setPeople(data);
        } else {
            setPeople(consts.dashboard)
        }
    }
    const filtersHtml = useMemo(() => {
        if (filters.length == 0) return null;
        return (<div className="row rtl filter">
            {filters.map(group => <div className="col-lg-2 col-md-3" key={group.key}><JustSelect title={getText("type" + group.key)} key={group.key} data-target={group.key}>
                <option></option>
                {group.value.map(item => <option value={item.Id} key={item.Id}>{pickText(item.Ar, item.En)}</option>)}
            </JustSelect></div>)}
            <div className='col-12 col-lg-2 col-md-3 text-center'><span className="btn btn-primary" onClick={handleFilter}>{getText("search")}</span></div>
        </div>)
    }, [lng, filters])
    return (
        <>
            <Header>
                <div className="text-center">
                    <span className="btn btn-primary" onClick={() => setOrder({})}>{getText("sendrequestnow")}</span>
                </div>
            </Header>
            <div className="container">
                <div className="container-fluid py-4">
                    {filtersHtml}
                    <div className="row mt-4">
                        {people === null ? <LoadingSpinner title={getText("pleasewait")} /> : people?.length > 0 ? people.map((item, index) => <ItemCard
                            key={item.Id}
                            index={index}
                            item={item}
                            onImageClicked={() => {
                                setImg("/content/" + item.Photo);
                            }}
                            onRequest={() => {
                                setOrder(item)
                            }}
                        />) : null}
                        {/* {data.map((item, index) => <ItemCard key={item.Id} index={index} item={item} onClick={() => setOrder(item)} />)} */}
                    </div>
                </div>
            </div>
            <FooterInfo />
            {order && <Request order={order} setOrder={setOrder} callback={(updated) => {
                if (updated) {
                    handleFilter();
                }
            }} />}
            <ImgPreview img={img} setImg={setImg} />
            <div className="floting-buttons">
                <a href='https://wa.me/+966135756111' onClick={e => e.stopPropagation()} target="_blank" className="hover-effect scale bg-success text-white"><i className="fa-brands fa-whatsapp fs-4"></i></a>
                {/* <a href='tel:00966135756111' onClick={e => e.stopPropagation()} target="_blank" className="hover-effect scale bg-dark text-white"><i className="fa-solid fa-phone fs-6"></i></a> */}
            </div>
        </>
    )

}

const ImgPreview = ({ img, setImg }) => {
    if (!img) return null;
    return (
        <BModel size='xl' show={img} setShow={() => setImg(null)} cancelText={getText('close')}>
            <img src={img} style={{ width: "100%" }} className='hover-effect scale' onClick={e => {
                $(e.target).closest('.modal-dialog').toggleClass('modal-fullscreen')
            }} />
        </BModel>
        // <div className="img-preview bg-gradient-dark"><span className='cls bg-gradient-warning d-flex justify-content-center align-items-center shadow hover-effect scale' onClick={(e) => {
        //     console.log($(e.target).closest('.img-preview'));
        //     $(e.target).closest('.img-preview').slideDown('slaw', () => {

        //         // setImg(null)
        //     });
        // }} style={{ borderRadius: 600, height: 60, width: 60 }}><i className="fa-regular fa-circle-xmark fs-3 text-white"></i></span><img src={img} /></div>
    )
}
const emptyRecord = {
    Name: "",
    IdentityId: "",
    Dob: "",
    HasVisa: false,
    VisaNumber: "",
    VisaDate: "",
    Phone: "",
    WorkPlace: "",
    WorkCity: "",
    RelativeName: "",
    RelativeType: "",
    RelativePhone: "",
    BorderNo: "",
    RelativeWorkPlace: "",
    Address: "",
    FloorCount: 1,
    RoomsCount: 1,
    FamilyCount: 1,
};
const Request = ({ order, setOrder, callback }) => {
    const valCode = Math.floor(Math.random() * 90000) + 10000;
    const lang = useRecoilValue(languageState);
    const [printd, setPrintd] = useState(null);
    const validationSchema = useMemo(() => {
        const rf = getText("requiredf");
        const ff = Yup.string().required(rf).matches(
            /^(05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/gm,
            getText('invalidphone')
        );
        const reqifstring = Yup.string().when("HasVisa", {
            is: true,
            then: schema => schema.required(rf)
        });
        const reqifnumber = Yup.number().when("HasVisa", {
            is: true,
            then: schema => schema.required(rf).min(1)
        });
        const reqifphone = Yup.string().when("HasVisa", {
            is: true,
            then: schema => schema.required(rf).matches(
                /^(05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/gm,
                getText('invalidphone')
            )
        })
        return Yup.object({
            Name: Yup.string().required(rf),
            IdentityId: Yup.string()
                .required(rf)
                .max(10, getText('identitymax'))
                .matches(/^[0-9]{10}$/gm, getText('invalidvalue')),
            Dob: Yup.string().required(rf),
            Phone: ff,
            valCode: Yup.string(),
            Code: Yup.string().required(rf).oneOf([Yup.ref('valCode')], "Code does not match"),
            HasVisa: Yup.boolean(),
            VisaNumber: reqifstring,
            VisaDate: reqifstring,
            WorkPlace: reqifstring,
            WorkCity: reqifstring,
            RelativeName: reqifstring,
            RelativePhone: reqifphone,
            RelativeWorkPlace: reqifstring,
            RelativeType: reqifstring,
            Address: reqifstring,
            FloorCount: reqifnumber,
            RoomsCount: reqifnumber,
            FamilyCount: reqifnumber,
            BorderNo: Yup.string().when("HasVisa", {
                is: true,
                then: schema => schema.required(rf).max(10)
            })
        })
    }, [lang])
    return (
        <>
            <Formik
                // validateOnBlur={false}
                // validateOnChange={false}
                validationSchema={validationSchema}
                initialValues={{ ...emptyRecord, valCode }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    $.ajax({
                        url: "/api/requests/submit",
                        method: "POST",
                        data: { ...values, PersonId: order.Id }
                    }).then((resp) => {
                        setPrintd(resp);
                        // Hint.Success(getText("sent") + "<br/>" + getText("refrencenumber") + `<b className="mx-2">${resp.id}</b>`)
                        resetForm();
                        consts.dashboard = consts.dashboard.filter(c => c.Id != order.Id);
                        if (callback) callback(true);
                    }).always(() => setSubmitting(false))
                    // console.log(values)
                    // setSubmitting(false);
                }}
            >
                {({ handleSubmit, isSubmitting, values }) => (
                    <BModel show={order} setShow={() => setOrder(null)} title={getText("requesttitle")} cancelText={getText("cancel")} okText={getText("send")} theme='primary' handleSubmit={handleSubmit} isSubmitting={isSubmitting}>
                        <div className="row rtl">
                            <RequestForm values={values} valCode={valCode} key="client" />
                        </div>
                    </BModel>
                )}
            </Formik>
            {printd && <BModel title={getText('preview')} okText={getText('print')} cancelText={getText('close')} handleSubmit={() => {
                PrintElement(document.querySelector('[data-report-container]'))
            }} show={printd} setShow={(() => {
                setPrintd(null);
                setOrder(null);
            })}>
                <div className="text-center">{getText("sent")}</div>
                <hr className='dark horizontal m-0 my-1' />
                <Report data={printd} setData={() => {
                    setPrintd(null);
                    setOrder(null);
                }} />
            </BModel>}
        </>
    )
}
export const RequestForm = ({ values, valCode }) => {
    return (
        <>
            <div className="float-end"></div>
            {valCode == null && <div className="col-md-6">
                <FormSelect propName="Status" title={getText("requeststatus")}>
                    <option></option>
                    {consts.filters.filter(c => c.Type === 5).map(item => <option key={item.Id} value={item.Id}>{pickText(item.Ar, item.En)}</option>)}
                </FormSelect>
            </div>}
            <div className="col-md-6">
                <FormInput propName="Name" title={getText("name")} required />
            </div>
            <div className="col-md-6">
                <FormInput propName="IdentityId" title={getText("identityid")} required maxLength={10} />
            </div>
            <div className="col-md-6">
                <FormInput propName="Dob" title={getText("dob") + " - " + getText("hjri")} required maxLength={10} />
            </div>
            <div className="col-md-6">
                <FormInput propName="Phone" title={getText("phone")} required maxLength={10} />
            </div>
            <div className="col-md-6">
                <FormCheckBox propName="HasVisa" title={getText("hasvisa")} />
            </div>
            <hr className="dark horizontal iam-here"></hr>
            {values.HasVisa === true ? <>
                <div className="col-md-6">
                    <FormInput propName="VisaNumber" title={getText("visanumber")} maxLength={100} />
                </div>
                <div className="col-md-6">
                    <FormInput propName="VisaDate" title={getText("visadate") + " - " + getText("hjri")} maxLength={10} />
                </div>
                <div className="col-md-6">
                    <FormInput propName="BorderNo" title={getText("borderno")} maxLength={10} />
                </div>

                <div className="col-md-6">
                    <FormInput propName="WorkPlace" title={getText("workplace")} required maxLength={100} />
                </div>
                <div className="col-md-6">
                    <FormInput propName="WorkCity" title={getText("workcity")} maxLength={100} required />
                </div>
                <div className="col-md-6">
                    <FormInput propName="Address" title={getText("address")} required maxLength={100} />
                </div>
                <div className="col-md-6">
                    <FormInput propName="RelativeName" title={getText("reltivename")} required maxLength={150} />
                </div>
                <div className="col-md-6">
                    <FormInput propName="RelativeType" title={getText("relativetype")} maxLength={100} />
                </div>
                <div className="col-md-6">
                    <FormInput propName="RelativePhone" maxLength={10} title={getText("reltivephone")} required />
                </div>
                <div className="col-md-6">
                    <FormInput propName="RelativeWorkPlace" title={getText("reltiveworkplace")} required maxLength={100} />
                </div>

                <div className="col-md-6">
                    <FormInput propName="FloorCount" title={getText("floorcount")} required />
                </div>
                <div className="col-md-6">
                    <FormInput propName="RoomsCount" title={getText("roomscount")} required />
                </div>
                <div className="col-md-6">
                    <FormInput propName="FamilyCount" title={getText("familycount")} required />
                </div>
            </> : null}
            {valCode && <>
                <div className="col-md-4 col-sm-8 col-9">
                    <FormInput propName="Code" type="text" title={getText("code")} required maxLength={5} />
                </div>
                <div className="col-md-2 col-sm-4 col-3 align-self-center">
                    <span className="bg-primary p-1 px-2 rounded text-bg-primary">
                        <span className='small'>{valCode}</span>
                    </span>
                </div>
            </>}
            <div className="col-12">
                <FormTextArea propName="Note" title={getText("notes")} maxLength={200} />
            </div>
        </>
    )
}

const Footer = () => {
    return (
        <footer className="bd-footer py-5 mt-5 bg-primary">
            <div className="container py-5">
                <div className="row rtl">
                    <div className="col-sm-12 col-lg-6 mb-3">
                        <a className="d-inline-flex align-items-center mb-2 link-dark text-decoration-none" href="/" aria-label="Bootstrap">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="32" className="d-block me-2" viewBox="0 0 118 94" role="img">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.509 0c-6.733 0-11.715 5.893-11.492 12.284.214 6.14-.064 14.092-2.066 20.577C8.943 39.365 5.547 43.485 0 44.014v5.972c5.547.529 8.943 4.649 10.951 11.153 2.002 6.485 2.28 14.437 2.066 20.577C12.794 88.106 17.776 94 24.51 94H93.5c6.733 0 11.714-5.893 11.491-12.284-.214-6.14.064-14.092 2.066-20.577 2.009-6.504 5.396-10.624 10.943-11.153v-5.972c-5.547-.529-8.934-4.649-10.943-11.153-2.002-6.484-2.28-14.437-2.066-20.577C105.214 5.894 100.233 0 93.5 0H24.508zM80 57.863C80 66.663 73.436 72 62.543 72H44a2 2 0 01-2-2V24a2 2 0 012-2h18.437c9.083 0 15.044 4.92 15.044 12.474 0 5.302-4.01 10.049-9.119 10.88v.277C75.317 46.394 80 51.21 80 57.863zM60.521 28.34H49.948v14.934h8.905c6.884 0 10.68-2.772 10.68-7.727 0-4.643-3.264-7.207-9.012-7.207zM49.948 49.2v16.458H60.91c7.167 0 10.964-2.876 10.964-8.281 0-5.406-3.903-8.178-11.425-8.178H49.948z" fill="currentColor"></path></svg> */}
                            <span className="fs-5">{getText('title')}</span>
                        </a>
                        <ul className="list-unstyled small text-muted">
                            <li className="mb-2 text-bg-primary" >{getText('yusdesc')}</li>
                        </ul>
                    </div>
                    <div className="col-sm-12 col-lg-3 mb-3 offset-lg-2">
                        <h5 className="text-white">{getText('beintouch')}</h5>
                        <ul className="list-unstyled small p-0">
                            <li className="mb-2"><a className="hover-effect text-bg-primary" target='_blank' href="https://www.google.com/maps?q=25.36372184753418,49.56819534301758&z=17&hl=en"><i className="fa-solid fa-map-pin" ></i><span className='mx-2'>{getText('theaddress')}</span></a></li>
                            <li className="mb-2"><a className="hover-effect text-bg-primary" target='_blank' href="https://wa.me/+966135754000"><i className="fa-brands fa-whatsapp"></i><span className="mx-2">+966135754000</span></a></li>
                            <li className="mb-2"><a className="hover-effect text-bg-primary" target='_blank' href="tel:+966590005552"><i className="fa-solid fa-phone"></i><span className="mx-2">0590005552</span></a></li>
                            <li className="mb-2"><a className="hover-effect text-bg-primary" target='_blank' href="mailto:almufawd@hotmail.com"><i className="fa-solid fa-envelope"></i><span className='mx-2'>almufawd@hotmail.com</span></a></li>
                            <li className="mb-2">
                                <Link to="/login" className="hover-effect text-bg-primary">
                                    <span>{getText('userlogin')}</span>
                                </Link>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}