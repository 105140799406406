import React, { useState, useEffect, useMemo } from 'react'
import DHead from '../components/DHead'
import DefaultTable from '../components/DTable';
import * as Yup from "yup"
import LoadingSpinner from '../components/LoadingSpinner';
import { DateCell, consts, PrintElement } from '../Utlities/functions';
import $ from "jquery"
import { getText, languageState, pickText } from '../Utlities/app-store';
import { useRecoilValue } from 'recoil';
import { RequestForm } from './ClientPage';
import BModel from '../components/BModel';
import Report from '../components/Report';
// import { PrintElement } from '../Utlities/printForm';
// validation schema
const FormContent = (props) => {

    return (
        <>
            <ChangeWorker {...props} />
            <RequestForm values={props.values} key="requests" />
        </>
    )
}

const ChangeWorker = React.memo(({ values, setFieldValue }) => {
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);
    function loadData(showValue = true) {
        setLoading(true);
        $.ajax("/api/persons/getdata").then(data => {
            setData(data);
            setShow(showValue)
        }).catch(() => setData([])).always(() => setLoading(false));
    }
    return (
        <>
            <div className="btn-group btn-group-sm d-flex">
                <div className="btn btn-info text-center flex-grow-1" disbaled style={{ cursor: "none" }}>{values.Person ? (getText('employee') + " : " + values.Person?.Name + `    (#${values.PersonId})`) : "لم يتم تحديد العامل بعد"}</div>
                <div className="btn btn-danger text-center flex-grow-0" onClick={() => {
                    setFieldValue('PersonId', "")
                    setFieldValue('Person', null)
                }}>{getText('cancel')}</div>
                <div className="btn btn-warning text-center flex-grow-0" disabled={loading} onClick={() => {
                    if (data.length > 0) {
                        setShow(true);
                    } else {
                        loadData()
                    }
                }}>{getText('setemployee')}</div>
            </div>
            <div className="col-9 d-none">
                <div className="bg-info text-white p-2 rounded">
                    <div className="text-center">{values.Person ? (getText('employee') + " : " + values.Person?.Name + `    (#${values.PersonId})`) : "لم يتم تحديد العامل بعد"}</div>
                </div>
            </div>
            <div className="col-3 d-none">
                <div className="btn-group btn-group-sm">
                    <button type="button" className='btn btn-danger' onClick={() => {
                        setFieldValue('PersonId', "")
                        setFieldValue('Person', null)
                    }}>{getText('cancel')}</button>
                    <button type="button" className='btn btn-warning' disabled={loading} onClick={() => {
                        if (data.length > 0) {
                            setShow(true);
                        } else {
                            loadData()
                        }
                    }}>{getText('setemployee')}</button>
                </div>
            </div>
            <hr className='dark horizontal my-4 col-12' />
            {show &&
                <BModel show={show} setShow={setShow} size='xl' title={getText('setemployee')} cancelText={getText("cancel")}>
                    <div className="row">
                        {data.map(item => <div className='col-md-3 col-sm-2' key={item.Id}>
                            <div className="card bg-dark text-white hover-effect scale" onClick={() => {
                                setShow(false);
                                setFieldValue('PersonId', item.Id)
                                setFieldValue('Person', item)
                            }} title='إضغط للإختيار'>
                                <img src={`/content/` + item.Photo} className="card-img" alt="..." />
                            </div>
                        </div>)}
                    </div>
                </BModel>
            }
        </>
    )
})

export default function RequestsPage() {
    const [loaded, setLoaded] = useState(false);
    const [report, setReport] = useState(null);
    const [employee, setEmployee] = useState(null);
    const lng = useRecoilValue(languageState)
    useEffect(() => {
        if (!loaded) {
            $.ajax("/api/filters/getdata").then(data => {
                consts.filters = data;
                setLoaded(true);
            })
        }
    }, [])
    const { validationSchema, shortValidationSchema } = useMemo(() => {
        {
            const rf = getText("requiredf");
            const ff = Yup.string().required(rf).matches(
                /^(05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/gm,
                getText('invalidphone')
            );
            const reqifstring = Yup.string().test("test", rf, (value, context) => {
                return !context.parent.HasVisa || (context.parent.HasVisa && value?.length > 0);
            })
            const reqifnumber = Yup.number().when("HasVisa", {
                is: true,
                then: schema => schema.required(rf).min(1)
            });
            const reqifphone = Yup.string().when("HasVisa", {
                is: true,
                then: schema => schema.required(rf).matches(
                    /^(05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/gm,
                    getText('invalidphone')
                )
            })
            const validationSchema = Yup.object({
                Name: Yup.string().required(rf),
                IdentityId: Yup.string()
                    .required(rf)
                    .max(10, getText('identitymax'))
                    .matches(/^[0-9]{10}$/gm, getText('invalidvalue')),
                Dob: Yup.date().required(rf),
                Phone: ff,
                HasVisa: Yup.boolean(),
                VisaNumber: reqifstring,
                VisaDate: Yup.date().when("HasVisa", {
                    is: true,
                    then: schema => schema.required(rf)
                }),
                BorderNo: reqifstring,
                WorkPlace: reqifstring,
                WorkCity: reqifstring,
                RelativeName: reqifstring,
                RelativePhone: reqifphone,
                RelativeWorkPlace: reqifstring,
                RelativeType: reqifstring,
                Address: reqifstring,
                FloorCount: reqifnumber,
                RoomsCount: reqifnumber,
                FamilyCount: reqifnumber,
                // BorderNo: Yup.string().test("test", rf, (value, context) => {
                //     return !(context.parent.HasVisa === true) || (context.parent.HasVisa && value?.length > 0);
                // })
            })
            const shortValidationSchema = Yup.object({
                Name: Yup.string().required(rf),
                IdentityId: Yup.string()
                    .required(rf)
                    .max(10, getText('identitymax'))
                    .matches(/^[0-9]{10}$/gm, getText('invalidvalue')),
                Dob: Yup.date().required(rf),
                Phone: ff,
                HasVisa: Yup.boolean(),
            })
            return { validationSchema, shortValidationSchema }
        }
    }, [lng])

    const { columns, title } = useMemo(() => {
        //"Id", "Id as Ref", "Name", "RequestDate", "Dob", "IdentityId", "HasVisa", "VisaNumber", "VisaDate", "Phone","WorkPhone", "WorkPlace", "RelativeName", "RelativePhone", "RelativeWorkPlace", "Address", "Note", "FloorCount", "RoomsCount", "PersonId", "Status"
        const columns = [
            { Header: getText('ref'), accessor: 'Ref', disableSortBy: true },
            { Header: getText('name'), accessor: 'Name', isSorted: true },
            { Header: getText('requestdate'), accessor: 'RequestDate', Cell: DateCell },
            {
                Header: getText('employee'), accessor: 'Person', disableSortBy: true, Cell: ({ value }) => {
                    if (!value) return "";
                    return (
                        <div onClick={() => setEmployee(value)} className='hover-effect'>#{value.Id} - {value.Name}</div>
                    )
                }
            },
            {
                Header: getText('requeststatus'), accessor: 'Status', Cell: ({ value }) => {
                    const stat = consts.filters.find(c => c.Id === value);
                    if (stat) return pickText(stat.Ar, stat.En)
                    return "";
                }
            },
            {
                Header: "", accessor: 'HasVisa', disableSortBy: true, Cell: ({ value, row }) => <button className='btn btn-success btn-sm py-1' onClick={() => {
                    setReport(row.original)
                }}><i className="fa-solid fa-print mr-6 fs-6"></i>{getText('print')}</button>
            }
        ];
        const title = getText("requests");
        return { columns, title }
    }, [lng])
    if (!loaded) return <LoadingSpinner />
    return (
        <>
            <DHead title={title} theme="info">
                <DefaultTable
                    url="/api/requests"
                    columns={columns}
                    theme="info"
                    title={title}
                    schema={shortValidationSchema}
                    FormContent={FormContent}
                    defaultValues={{
                        Id: 0,
                        Name: "",
                        IdentityId: "",
                        Dob: "",
                        HasVisa: false,
                        VisaNumber: "",
                        VisaDate: "",
                        BorderNo: "",
                        Phone: "",
                        WorkPlace: "",
                        WorkCity: "",
                        RelativeName: "",
                        RelativePhone: "",
                        RelativeWorkPlace: "",
                        Address: "",
                        FloorCount: 1,
                        RoomsCount: 1,
                        FamilyCount: 1,
                        Status: ""
                    }}
                    editorProps={{
                        size: "xl"
                    }}
                // onRowClicked={onRowClicked}
                />
            </DHead>
            {employee &&
                <BModel title={getText('employeeinfo')} cancelText={getText('close')} show={employee} setShow={() => setEmployee(null)} size='xl'>
                    <div className='row'>
                        <div className="col-md-3 col-sm-6 text-end border-bottom">#{getText('ref')}</div>
                        <div className="col-md-3 col-sm-6 text-center border-bottom">{employee.Id}</div>
                        <div className="col-md-3 col-sm-6 text-end border-bottom">#{getText('name')}</div>
                        <div className="col-md-3 col-sm-6 text-center border-bottom" >{employee.Name}</div>
                        <div className="col-12">
                            <img src={`/content/${employee?.Photo}`} width="100%" alt="" />
                        </div>
                    </div>
                </BModel>}
            {report && <BModel title={getText('preview')} okText={getText('print')} cancelText={getText('close')} handleSubmit={() => {
                //PrintElement
                PrintElement(document.querySelector('[data-report-container]'))
            }} show={report} setShow={(() => {
                setReport(null);
            })}>
                <Report data={report} setData={setReport} />
            </BModel>}
        </>
    )
}
