import $ from "jquery";
export function getRapidTheme(index) {
  // Define an array of values
  const values = ["primary", "secondary", "info", "success", "warning"];

  // Calculate the index based on the length of the array
  const calculatedIndex = index % values.length;

  // Return the value at the calculated index
  return values[calculatedIndex];
}

export function getRapidColor(index) {
  const values = [
    "blue",
    "green",
    "purple",
    "pink",
    "red",
    "orange",
    "yellow",
    "green",
    "teal",
    "cyan",
  ];
  // Calculate the index based on the length of the array
  const calculatedIndex = index % values.length;

  // Return the value at the calculated index
  return values[calculatedIndex];
}

export const ShallowEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
};

export const consts = {
  user: undefined,
  /**
   *
   * @param {Array} rolesArray an array of strings
   * @returns
   */
  isInRole: (rolesArray) => {
    if (consts.user) {
      return rolesArray.includes(consts.user.roles[0]);
    }
    return false;
  },
  /**
   * It checkes wether user is Editor
   * @returns Returns true or false
   */
  isEditor: () => consts.isInRole(["Editor", "Admin", "Developer"]),
  /**
   * It checks wether user is an Admin
   * @returns Returns true or false
   */
  isAdmin: () => consts.isInRole(["Admin", "Developer"]),
  /**
   *
   */
  setShowSpinner: null,
  filters: null,
  dashboard: [],
  // getText: (name) => {},
  // pickText: (ar, en) => {},
};

export const CheckCell = ({ value }) =>
  value ? (
    <i className="fa-solid fa-check"></i>
  ) : (
    <i className="fa-solid fa-xmark"></i>
  );

export const DateCell = ({ value }) => {
  return value ? (
    <>
      {DateTimeArabic(value)}
      <br /> {HijriDate(value)}
    </>
  ) : null;
};
export function updateDateProperties(obj) {
  for (let prop in obj) {
    if (typeof obj[prop] === "object" && obj[prop] !== null) {
      updateDateProperties(obj[prop]); // Recursively call the function for nested objects
    } else if (obj[prop] instanceof Date) {
      obj[prop] = DateToInputeDate(obj[prop]);
    } else if (typeof obj[prop] === "string") {
      const parsedDate = new Date(obj[prop]);
      if (!isNaN(parsedDate.getTime())) {
        obj[prop] = DateToInputeDate(parsedDate);
      }
    }
  }
}
export const DateToInputeDate = (date) => {
  if (!date) return "";
  if (typeof date === "string") {
    // Check if the input is already a string representation of a date
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      // If the parsed date is invalid, return the original input
      return date;
    }
    // If the parsed date is valid, assign it to the date variable
    date = parsedDate;
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export function ResizeImages(file, complete) {
  // read file as dataUrl
  ////////  2. Read the file as a data Url
  var reader = new FileReader();
  // file read
  reader.onload = function (e) {
    // create img to store data url
    ////// 3 - 1 Create image object for canvas to use
    var img = new Image();
    img.onload = function () {
      /////////// 3-2 send image object to function for manipulation
      complete(resizeInCanvas(img));
    };
    img.src = e.target.result;
  };
  // read file
  reader.readAsDataURL(file);
}
function resizeInCanvas(img) {
  /////////  3-3 manipulate image
  var perferedWidth = 600;
  var ratio = perferedWidth / img.width;
  var canvas = $("<canvas>")[0];
  canvas.width = img.width * ratio;
  canvas.height = img.height * ratio;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  //////////4. export as dataUrl
  return canvas.toDataURL();
}
export const ClearBase64Url = (url) => {
  const b = ";base64,";
  return url.substring(url.indexOf(b) + b.length, url.length);
};

export function maxLen(max) {
  return `لايمكن ان يكون عدد احرف الحقل اكثر من ${max} حرف`;
}

export const orderStatus = [
  "تم إرسال الطلب",
  "الطلب تحت الدراسة",
  "مطلوب بيانات إظافية",
  "تمت الموافقة",
  "تم إيداع المبلغ",
  "إنتظار التسديد",
  "تم التسديد",
  "مكتمل",
  "ملغي",
  "مرفوض",
];
/**
 * Get the arabic transalation of a status
 * @param {number} status
 */
export function OrderTypeTransaltion(status) {
  return orderStatus[status];
}
/**
 * Get bootstrap theme
 * @param {number} status
 */
export function StatusTheme(status) {
  if ([0, 1, 2].includes(status)) return "warning";
  else if ([3].includes(status)) return "info";
  else if ([4, 5, 6, 7].includes(status)) return "success";
  else if ([8, 9].includes(status)) return "danger";
  return "";
}

export function GroupArrayByYearMonth(array) {
  const groupsByYear = {};

  array.forEach((item) => {
    let date = item.Date;

    if (typeof date === "string") {
      date = new Date(date);
    }

    const year = date.getFullYear();

    if (!groupsByYear[year]) {
      groupsByYear[year] = {};
    }

    const month = date.getMonth();

    if (!groupsByYear[year][month]) {
      groupsByYear[year][month] = [];
    }

    groupsByYear[year][month].push(item);
  });

  const result = Object.keys(groupsByYear).map((year) => {
    const months = groupsByYear[year];
    const yearResult = {
      year: parseInt(year),
      months: [],
    };

    Object.keys(months).forEach((month) => {
      const monthItems = months[month];
      const date = new Date(parseInt(year), parseInt(month));

      yearResult.months.push({
        month: parseInt(month),
        date,
        items: monthItems,
      });
    });

    return yearResult;
  });

  return result;
}

export function groupBy(array, key) {
  const groupedData = array.reduce((result, obj) => {
    const groupKey = obj[key];

    if (!result[groupKey]) {
      result[groupKey] = [];
    }

    result[groupKey].push(obj);
    return result;
  }, {});

  return Object.entries(groupedData).map(([key, value]) => ({
    key,
    value,
  }));
}

// function name(params) {
//   "len",
//     "can be empty or with string at least 2 characters and not more than 10",
//     (val) => {
//       if (val === undefined) {
//         return true;
//       }
//       return val.length === 0 || (val.length >= 2 && val.length <= 10);
//     }
// }

export function DateTimeArabic(date, locales = "ar-SA") {
  try {
    return new Intl.DateTimeFormat(locales, {
      dateStyle: "full",
      timeStyle: "short",
      calendar: "gregory",
      numberingSystem: "arab",
    }).format(new Date(date));
  } catch (error) {
    return new Date(date).toLocaleString();
  }
}
export function HijriDate(date) {
  return new Intl.DateTimeFormat("ar-TN-u-ca-islamic", {
    day: "numeric",
    month: "long",
    weekday: "long",
    year: "numeric",
  }).format(new Date(date));
}

const DIVISIONS = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
];
const formatter = new Intl.RelativeTimeFormat("ar-SA", {
  numeric: "auto",
});
/**
 *
 * @param {Date} date
 */
export function FormatTimeAge(date) {
  let duration = (new Date(date) - new Date()) / 1000;
  for (let i = 0; i < DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
}

export function PrintElement(element) {
  const styles = Array.from(document.styleSheets)
    .map((sheet) => {
      if (sheet.href) {
        return `<link rel="stylesheet" href="${sheet.href}">`;
      } else {
        const rules = Array.from(sheet.cssRules || []);
        const cssText = rules.map((rule) => rule.cssText).join("\n");
        return `<style>${cssText}</style>`;
      }
    })
    .join("\n");
  var iframe = document.createElement("iframe");
  document.body.appendChild(iframe);
  var pri = iframe.contentWindow;
  pri.document.open();
  pri.document.write(styles + "\n\n" + element.outerHTML);
  pri.document.close();
  pri.focus();
  pri.print();
  setTimeout(() => {
    iframe.remove();
  }, 2000);

  // if (!element) {
  //   console.error(`Element not found.`);
  //   return;
  // }
  // const elementId = "xx";
  // const stylesheets = document.styleSheets;

  // const printWindow = window.open("", "_blank");
  // printWindow.document.open();
  // printWindow.document.write(`
  //   <html>
  //     <head>
  //       <title>Print</title>
  //       ${Array.from(stylesheets)
  //         .map((sheet) => {
  //           if (sheet.href) {
  //             return `<link rel="stylesheet" href="${sheet.href}">`;
  //           } else {
  //             const rules = Array.from(sheet.cssRules || []);
  //             const cssText = rules.map((rule) => rule.cssText).join("\n");
  //             return `<style>${cssText}</style>`;
  //           }
  //         })
  //         .join("\n")}
  //     </head>
  //     <body>
  //       ${element.outerHTML}
  //     </body>
  //   </html>
  // `);
  // setTimeout(() => {
  //   printWindow.print();
  //   printWindow.close();
  // }, 1000);
  // printWindow.document.close();
  // printWindow.onload = function () {
  //   printWindow.print();
  //   // printWindow.print();
  //   printWindow.close();
  // };
}
