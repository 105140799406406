import { useFormikContext } from 'formik'
import React, { useMemo, useState, useRef } from 'react'

export default function JustInput({ propName, required, inputProps, containerClassName, title, type = "text", subText, children, ...etc }) {
    const inpRef = useRef(null);
    return (
        <div className="input-group input-group-outline mb-3">
            <span className='input-group-prepend'>
                <span class="input-group-text">{title} {required && <span className='mx-1 text-danger'>*</span>}</span>
            </span>
            <input ref={inpRef} type={type} className="form-control"
                {...etc}
            />
            {children}
        </div>
    )
}
