import { useFormikContext } from 'formik'
import React, { useMemo, useState } from 'react'

export default function FormInput({ title, propName, topInfoText, bottomInfoText, required, inputProps, containerProps, containerClassName, className = "", type = "text", children, iconLeft, iconRight, ...etc }) {
    const { isSubmitting, values, handleChange, errors } = useFormikContext();
    const navigator = useMemo(() => propName ?? title, [])
    const invalid = useMemo(() => errors && errors[navigator], [errors])
    const defaultClass = useMemo(() => {
        return `form-group ${(iconLeft || iconRight) ? `position-relative has-icon-${iconLeft ? 'left' : 'right'}` : ''} ${containerClassName ?? ''}`
    }, [])
    return (
        <div className={defaultClass} {...containerProps}>
            {title && <label>{title} {required && <span className='mx-1 text-danger'>*</span>}</label>}
            {topInfoText && <small className="text-muted">{topInfoText}</small>}
            <input type={type} className={`form-control ${invalid ? 'is-invalid' : values[navigator] ? 'is-valid' : ''} ${className}`} disabled={isSubmitting} onChange={handleChange(navigator)} value={(values[navigator] ?? "")}
                {...inputProps} {...etc}
            />
            {(iconLeft || iconRight) ? <div className="form-control-icon">
                {iconLeft ?? iconRight}
            </div> : null}
            {bottomInfoText && <small className="text-muted">{bottomInfoText}</small>}
            {invalid && <div className="invalid-feedback">
                {errors[navigator]}
            </div>}
            {children}
        </div>
    )
}
