import { useFormikContext } from 'formik'
import React, { useMemo, useState } from 'react'

export default function FormSelect({ propName, required, inputProps, containerClassName, title, type = "text", children, ...etc }) {
    const { isSubmitting, values, handleChange, errors } = useFormikContext();
    const invalid = useMemo(() => errors && errors[propName], [errors])
    const navigator = useMemo(() => propName ?? title, [])
    return (
        <>
            <div className={`form-group mb-3${(values[navigator] ? ' is-focused' : '')}`}>
                <label>{title} {required && <span className='mx-1 text-danger'>*</span>}</label>

                <select
                    value={values[navigator] ?? ""}
                    disabled={isSubmitting}
                    onChange={handleChange(navigator)}
                    className={`form-select ${invalid ? 'is-invalid' : values[navigator] ? 'is-valid' : ''}`} {...inputProps} {...etc}>
                    {children}
                </select>
                {invalid && <div className="invalid-feedback">
                    {errors[navigator]}
                </div>}
            </div>
        </>

    )
}