import React from 'react'
import HeaderInfo, { LoginPart } from './HeaderInfo'
import NokhbaLogo from './NokhbaLogo'
import { Link } from 'react-router-dom'
import { getText, languageState } from '../Utlities/app-store'


//className="logo-here p-5 mb-5 text-center" style={{ backgroundImage: `url('/content/bg.jpg')` }}
export default function Header({ children }) {
    return (
        <>
            <div className="container d-block">
                <Link to="/login">
                    {getText('login')}
                    {/* <i className="fa-solid fa-door-closed fs-6"></i> */}
                </Link>
            </div>
        </>
    )
}


const Old = ({ children }) => {
    <>
        <div className='main-header'>
            <div className="container">
                <div className="row pt-3">
                    <div className="col-6 z-index-1"><img src='/content/m-logo.png' data-m-logo height={60} /></div>
                    <div className="col-6 d-flex flex-row-reverse z-index-1"><img src='/content/h-logo.png' data-h-logo height={80} /></div>
                    <div className="col-12 d-flex align-items-baseline justify-content-center"><HeaderInfo /></div>
                    <div className="col-12 z-index-1 d-flex flex-column justify-content-center align-items-center">
                        <NokhbaLogo width={250} className="mb-4" />
                        {/* <LoginPart /> */}
                        {/* <img src='/content/f-logo.png' data-f-logo height={150} /> */}
                    </div>
                </div>
            </div>
        </div>
        {children}
    </>
}