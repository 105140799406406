import { atom } from "recoil";
import { ar, en } from "./language";
import { getRecoil } from "recoil-nexus";
import axios from "axios";

//atom مكون نشتي نحفظة

// الثلاث التاليات مايشتغلين الا اذا ارسلت لهن ATOM
//useRecoilState  simplar to react useState
//useSetRecoilState used for set only
//useRecoilValue use value only

export const userState = atom({
  key: "user",
  default: null,
});

export const loadingState = atom({
  key: "loading",
  default: false,
});

export const languageState = atom({
  key: "language",
  default: "ar",
});
export const getText = (name) => {
  return getRecoil(languageState) == "ar" ? ar[name] : en[name];
};

export const pickText = (arabic, english) => {
  return getRecoil(languageState) == "ar"
    ? arabic ?? english
    : english ?? arabic;
};

export const axiosClient = axios.create();
