import React from 'react'
import { useRecoilState } from 'recoil'
import { languageState } from '../Utlities/app-store'

export default function LanguageChanger({ className, ...etc }) {
    const [lng, setLng] = useRecoilState(languageState);
    return (
        <span className={`hover-effect ${className ?? ''}`} {...etc} onClick={() => {
            setLng(lng == 'ar' ? 'en' : 'ar')
        }} title='Change Language | تغيير اللغة'><i className="fa-solid fa-earth-americas"></i></span>
    )
}
