export const ar = {
  type0: "الدولة",
  type1: "الوظيفة",
  type2: "النوع",
  type3: "الخبرة",
  type4: "الديانة",
  type5: "حالة الطلب",
  search: "البحث",
  pleasewait: "الرجاء الإنتظار",
  whatsapp: "وتسـاب",
  requestnow: "إطلب الأن",
  requesttitle: "تقديم طلب",
  name: "الإسم",
  identityid: "رقم الهوية",
  dob: "تاريخ الميلاد",
  phone: "رقم الهاتف",
  hasvisa: "لدي تأشيرة",
  borderno: "رقم الحدود",
  visanumber: "رقم الصادر",
  visadate: "تاريخ الصادر",
  workplace: "جهة العمل",
  workcity: "مدينة العمل",
  reltivename: "اسم احد اقارب من الدرجة الأولى",
  reltivephone: "تلفون القريب",
  reltiveworkplace: "جهة عمل الشخص القريب",
  address: "العنوان",
  floorcount: "عدد ادوار المنزل",
  roomscount: "عدد الغرف في المنزل",
  familycount: "عدد افراد الأسرة",
  notes: "ملاحظة",
  send: "إرسال",
  cancel: "إلغاء",
  requiredf: "هذا الحقل مطلوب",
  code: "كود التأكيد",
  identitymax: "لايمكن ان يكون رقم الهوية اكثر من 10 ارقام",
  invalidvalue: "القيمة غير مقبولة",
  invalidphone: "رقم الهاتف غير صحيح",
  sent: "تم الإرسال بنجاح",
  close: "إغلاق",
  refrencenumber: "رقم الطلب #",
  notification: "تنوية",
  login: "تسجيل الدخول",
  password: "رمز المرور",
  confirmpassword: "تأكيد رمز المرور",
  forgetpassword: "نسيت كلمة السر",
  resetpassword: "إعادة تعيين كلمة المرور",
  email: "البريد الإلكتروني",
  signin: "تسجيل الدخول",
  enter: "الدخول",
  enteryouremail: "الرجاء كتابة بريدك الإلكتروني",
  irememberedpassword: "لقد تذكرت رمز المرور",
  minpassword: "كلمة المرور يجب أن تكون أكثر أو تساوي 8 أحرف",
  confirmdoesnotmatch: "لا يتطابق مع حقل كلمة المرور.",
  lockedaccount: "الحساب مقفل، الرجاء الاتصال بإدارة الموقع للمراجعة",
  accountnotactive:
    "لم يتم تأكيد بريدك الالكتروني. الرجاء مراجعة بريدك الالكتروني لتفعيل حسابك",
  noaccountmatch: "لا يوجد حساب مطابق للمعلومات المدخلة",
  emailsenttoresetpass:
    "تم إرسال بريد إلكتروني لإعادة تعيين كلمة المرور الخاصة بك ، يرجى اتباع الخطوات المذكورة في البريد الإلكتروني.",
  selectnewpass: "الرجاء إختيار رمز مرور جديد",
  passwordupdated: "تم تغيير رمز المرور بنجاح",
  changepassword: "تغيير رمز المرور",
  resetcodesent:
    "تم إرسال بريد إلكتروني برمز إعادة التعيين إلى بريدك الإلكتروني ، يرجى التحقق من بريدك الإلكتروني وكتابة الرمز الذي تلقيته.",
  typecodehere: "اكتب الرمز هنا",
  codeexpired: "انتهت صلاحية الرمز",
  codeexpires: "تنتهي صلاحية الرمز خلال 5 دقائق",
  checkcode: "تحقق من صحة الكود",
  requestnewcode: "أطلب كود جديد",
  codenotmatch:
    "الرمز غير مطابق ، يرجى التحقق مما إذا كنت قد كتبت الرمز الصحيح أو طلبت رمزًا جديدًا.",
  dashboard: "الرئيسية",
  requests: "الطلبات",
  persons: "السير الذاتية",
  filters: "التصنيفات",
  users: "المستخدمين",
  new: "جديد",
  delete: "حذف",
  edit: "تعديل",
  pagelength: "حجم الجدول",
  search: "بحث",
  role: "الصلاحية",
  lockedenabled: "الحساب مغلق",
  gotopage: "أذهب الى الصفحة",
  page: "صفحة",
  outof: "من أصل",
  showing: "مشاهدة",
  records: "سجل",
  manage: "إدارة",
  arabic: "اللغة العربية",
  english: "اللغة الأنجليزية",
  disabled: "معطل",
  save: "حفظ",
  ref: "سجل",
  available: "متوفر",
  resume: "السيرة الذاتية",
  notsaved: "لم يتم الحفظ",
  saved: "تم الحفظ بنجاح",
  unabletoloadpreq:
    "تعذر تحميل بعض المتطلبات ، يرجى تحديث الصفحة والمحاولة مرة أخرى.",
  confirmation: "تأكيــــد",
  aresure: "هل أنت متأكد؟",
  yes: "نعم",
  deletedsuccessfully: "تم الحذف بنجاح",
  unabletodelete:
    "حدث خطاء اثناء الحظر او انه لا يمكن حذف البيانات المرتبطة بسجلات اخرى",
  nodatafound: "لم يتم العثور على اي بيانات.",
  developer: "المبرمج",
  admin: "إدارة",
  editor: "تعديل",
  reader: "قراءة فقط",
  countries: "الدول",
  statuses: "حالات الطلبات",
  jobs: "الوظائف",
  categories: "الأنواع",
  experiances: "الخبرات",
  religions: "الأديان",
  add: "إضافة",
  mustselectrole: "يجب إختيار صلاحية للمستخدم",
  requestdate: "تاريخ الطلب",
  requeststatus: "حالة الطلب",
  yus: "لماذا دار النخبة الافضل!",
  yusdesc:
    "أحد أقدم  مكاتب الاستقدام في المملكة العربية السعودية منذو 35 سنه ونحن نقدم خدمات استقدام العمالة المنزلية  ومن هذا تميزنا بألاستقدام السريع وبأسعار تنافسية ودعم متكامل على منصة مساند والخدمة الإلكترونية.",
  beintouch: "تواصل معنا",
  touchlocation: "اضغط هنا للوصول الى الموقع",
  touchemail: "الايميل",
  touchphone: "رقم الهاتف",
  logout: "تسجيل الخروج",
  employee: "العـامل",
  employeeinfo: "معلومات العامل",
  relativetype: "صلة القرابة",
  sendrequestnow: "تقدم بطلبك الان",
  hjri: "هجري",
  print: "طباعة",
  preview: "معاينة الطلب",
  setemployee: "تحديد | تغيير العامل",
  invalidemail: "البريد الإلكتروني غير صالح",
  theaddress: "الهفوف - شارع الرياض - تقاطع شارع حرض",
  title: "المفاوض الدولي للإستقدام",
  userlogin: "تسجيل دخول الموظفين",
};
export const en = {
  title: "International Negotiator Recruitment",
  theaddress: "Hofuf - Riyadh Street - Haradh Street Intersection",
  setemployee: "Set | Change Employee",
  preview: "Preview Application",
  print: "Print",
  hjri: "Hijri",
  relativetype: "Relative Type",
  borderno: "Border Number",
  employee: "The Employee",
  employeeinfo: "Employee Information",
  logout: "Logout",
  beintouch: "Contact Us",
  touchlocation: "Click here to navigate to our location",
  touchemail: "Email",
  touchphone: "Phone Number",
  yus: "Why US!",
  yusdesc:
    "One of the oldest recruitment offices in the Kingdom of Saudi Arabia for 35 years, we have been providing services for recruiting domestic workers, and from this we are distinguished by rapid recruitment because we have contributed to the electronic support and diversity platform.",
  statuses: "Request Statuses",
  saved: "Saved Successfully",
  requeststatus: "Request Status",
  requestdate: "Request Date",
  mustselectrole: "Role must be selected",
  add: "Add",
  countries: "Countries",
  jobs: "Jobs",
  categories: "Types",
  experiances: "Experiances",
  religions: "Religions",
  developer: "Developer",
  admin: "Admin",
  editor: "Editor",
  reader: "Reader",
  nodatafound: "No data found.",
  unabletodelete:
    "An error occurred while blocking or the data associated with other records cannot be deleted.",
  deletedsuccessfully: "Deleted Successfully",
  yes: "Yes",
  aresure: "Are you sure?",
  confirmation: "Confirmation:",
  unabletoloadpreq:
    "Some requirements could not be loaded, please refresh the page and try again.",
  notsaved: "Not saved!",
  dashboard: "Dashboard",
  requests: "Requests",
  persons: "CV",
  filters: "Filters",
  users: "Users",
  new: "New",
  delete: "Delete",
  edit: "Edit",
  pagelength: "Page Length",
  search: "Search",
  role: "Role",
  lockedenabled: "Locked",
  gotopage: "Go to page",
  page: "Page",
  outof: "out of",
  showing: "Showing",
  records: "records",
  manage: "Manage",
  arabic: "Arabic Language",
  english: "English Language",
  disabled: "Disabled",
  save: "Save",
  ref: "Ref",
  available: "Available",
  resume: "Resume",
  codenotmatch:
    "The code does not match, please check if you typed the right code or request a new code.",
  requestnewcode: "Request new code",
  checkcode: "Check Code",
  codeexpired: "The code is expire",
  codeexpires: "The code will expire within 5 Minutes",
  typecodehere: "Type code here",
  resetcodesent:
    "An email with a reset code has been sent to your email, please check your email and type the code you received.",
  changepassword: "Change Password",
  passwordupdated: "Password updated successfully!",
  selectnewpass: "Please select new password.",
  emailsenttoresetpass:
    "An email has been sent to reset your password, please follow the steps mentioned in the email.",
  noaccountmatch: "There is no account that matches the information entered.",
  accountnotactive:
    "Your email has not been confirmed. Please check your email to activate your account.",
  lockedaccount:
    "The account is locked, please contact the site administration for review.",
  confirmdoesnotmatch: "Does not match password field.",
  minpassword: "Password must be more or equal to 8 characters",
  irememberedpassword: "I remembered my passowrd",
  enteryouremail: "Please enter your email address",
  enter: "Enter",
  signin: "Sign In",
  email: "Email",
  login: "Login",
  password: "Password",
  confirmpassword: "Confirm Password",
  forgetpassword: "Forget Password",
  resetpassword: "Reset Password",
  notification: "Notification",
  refrencenumber: "Reference No. #",
  close: "Close",
  sent: "Sent Successfully!",
  invalidphone: "Invalid Phone",
  invalidemail: "Email is not valid",
  invalidvalue: "Ivalid Value",
  identitymax: "Identity length can not be more than 10 digits",
  code: "Verification Code",
  requiredf: "This field can not be empty!",
  cancel: "Cancel",
  send: "Send",
  notes: "Notes",
  familycount: "Number of family members",
  roomscount: "Number of rooms",
  floorcount: "Number of floors",
  address: "Address",
  reltiveworkplace: "The employer of the person close to you",
  reltivephone: "Relative Phone",
  reltivename: "Name of a first-degree relative",
  workcity: "Work City",
  workplace: "Work Place",
  visadate: "Visa Date",
  visanumber: "Visa Number",
  hasvisa: "Has Visa Already",
  phone: "Phone",
  dob: "Date of birth",
  identityid: "Identity Id",
  name: "Name",
  requesttitle: "Request Application",
  type0: "Country",
  type1: "Job",
  type2: "Category",
  type3: "Experiance",
  type4: "Religion",
  type5: "Request Status",
  search: "Search",
  pleasewait: "Please wait",
  whatsapp: "Whatsapp",
  requestnow: "Request Now",
  sendrequestnow: "Send your request now",
  userlogin: "Employee login",
};
