import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil';
import { axiosClient, userState } from '../Utlities/app-store';
import { useNavigate } from 'react-router-dom';
import $, { error } from "jquery"
import axios from 'axios';
import { consts } from '../Utlities/functions';

export default function JWTSetup({ children }) {
    const [user, setUser] = useRecoilState(userState);
    const nav = useNavigate();


    // useEffect(() => {
    //     function refreshToken() {
    //         console.log('refreshToken');
    //         return $.ajax({
    //             url: '/api/login/rt',
    //             method: 'POST'
    //         });
    //     }
    // }, [])
    useEffect(() => {
        // Add auth bearer to ajax
        const authInterceptor = axiosClient.interceptors.request.use(function (config) {
            if (user) {
                config.headers.Authorization = `Bearer ${consts.user.token}`;
            }
            return config;
        }, error => {
            return Promise.reject(error);
        });
        // refresh expired token
        const refreshInterceptor = axiosClient.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            const originalRequest = error.config;
            if (error.response && error.response.status === 401 && error.response.headers["www-authenticate"]?.toString().includes('token expired') && !originalRequest._retry) {
                originalRequest._retry = true;
                const x = await axiosClient.post("/api/login/rt").then(({ data }) => {
                    consts.user = data;
                    setUser(data);
                    originalRequest.headers.Authorization = "Bearer " + data.token;
                    return axiosClient(originalRequest);
                }).catch((err) => {
                    setUser(null);
                    nav("/login")
                    return Promise.reject(error)
                })
            } else {
                return Promise.reject(error);
            }
        });
        return () => {
            axiosClient.interceptors.request.eject(authInterceptor);
            axiosClient.interceptors.response.eject(refreshInterceptor);
        }
    }, [user])
    return children;
}



// function refreshToken(orignalAjax) {
//     $.ajax({
//       url: '/api/login/rt',
//       method: 'POST',
//       success: function (response) {
//         setUser(response);
//         // $.ajax(orignalAjax)
//         // Update the jwtToken variable with the new token
//         // jwtToken = response.token;

//         // Retry the original Ajax call
//         // retryOriginalAjaxCall();
//       },
//       error: function (xhr, textStatus, errorThrown) {
//         console.log("second")
//         // Handle token refresh error
//         if (xhr.status === 401) {
//           setUser(null);
//         }
//       }
//     });
//   }
