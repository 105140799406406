import React from 'react'
import "./singlepagelayout.css"
import { Link } from 'react-router-dom'

export default function SinglePageLayout({ title, subTitle, children }) {
    return (
        <div id="auth">
            <div class="row h-100">
                <div class="col-lg-5 col-12">
                    <div id="auth-left">
                        <div class="auth-logo">
                            <Link to="/">
                                <img src='/content/inr.png' style={{ height: "75px" }} />
                            </Link>
                        </div>
                        <h4 class="auth-title text-center">{title}</h4>
                        {subTitle && <p class="auth-subtitle mb-5">{subTitle}</p>}
                        {children}
                    </div>
                </div>
                <div class="col-lg-7 d-none d-lg-block">
                    <div id="auth-right">

                    </div>
                </div>
            </div>

        </div>
    )
}
