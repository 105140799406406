import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { getText, languageState, userState } from '../Utlities/app-store'
import { useRecoilState, useSetRecoilState } from 'recoil'
import NokhbaLogo from "./NokhbaLogo"
import "./mainlayout.css"
import MadeWithLove from './MadeWithLove'
import Footer from './Footer'

export default function MainLayout({ children }) {
    const [lng, setLng] = useRecoilState(languageState)
    const [user, setUser] = useRecoilState(userState);
    console.log(user)
    function toggleSide() {
        document.querySelector('#sidebar').classList.toggle('active')
    }
    return (
        <>
            <div id="sidebar" className="active">
                <div className="sidebar-wrapper active ps ps--active-y">
                    <div className="sidebar-header position-relative">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="logo">
                                <img src='/content/inr.png' style={{ height: "75px" }} />

                            </div>
                            <div className="sidebar-toggler x">
                                <span href="#" className="sidebar-hide d-xl-none d-block" onClick={toggleSide}><i className="bi bi-x bi-middle"></i></span>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-menu">
                        <ul className="menu">
                            <SideItem title={getText('dashboard')} to="/" iconclassName="bi bi-grid-fill" />
                            <SideItem title={getText('requests')} to="/requests" iconclassName="fa-solid fa-hand-holding-hand" />
                            <SideItem title={getText('persons')} to="/persons" iconclassName="fa-solid fa-users" />
                            <SideItem title={getText('filters')} to="/filters" iconclassName="fa-solid fa-filter" />
                            <SideItem title={getText('users')} to="/users" iconclassName="fa-solid fa-user-gear" />
                            <SideItem title={getText('logout')} setActive={false} to="/" onClick={e => setUser(null)} iconclassName="fa-solid fa-arrow-right-from-bracket" />
                        </ul>
                    </div>
                </div>
            </div>
            <div id="main">
                <header className="mb-3 d-flex justify-content-between">
                    <span className="burger-btn d-block d-xl-none" onClick={toggleSide}>
                        <i className="bi bi-justify fs-3"></i>
                    </span>
                    <div class="header-top-right">
                        <div class="dropdown">
                            <a href="#" id="topbarUserDropdown" class="user-dropdown d-flex align-items-center dropend dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <div class="avatar avatar-md2">
                                    <img src="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png" alt="Avatar" />
                                </div>
                                <div class="text">
                                    <h6 class="user-dropdown-name">{user?.Name}</h6>
                                    <p class="user-dropdown-status text-sm text-muted">{getText(user?.roles[0].toLowerCase())}</p>
                                </div>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end shadow-lg" aria-labelledby="topbarUserDropdown">
                                <li><a class="dropdown-item" href="#">My Account</a></li>
                                <li><a class="dropdown-item" href="#">Settings</a></li>
                                <li><hr class="dropdown-divider" /></li>
                                <li><a class="dropdown-item" href="auth-login.html">Logout</a></li>
                            </ul>
                        </div>
                    </div>
                </header>
                {children}
            </div>
        </>
    )
}

const Old = ({ children }) => {
    const [lng, setLng] = useRecoilState(languageState)
    const setUser = useSetRecoilState(userState);
    function toggleSide() {
        document.body.classList.toggle('g-sidenav-pinned')
    }
    <>
        <aside className={`sidenav navbar navbar-vertical navbar-expand-xs border-0 fixed-start bg-gradient-info ps bg-white`}>
            <div className="sidenav-header">
                <span onClick={toggleSide} className='p-3 cursor-pointer hover-effect scale text-white opacity-5 position-absolute end-0 top-0  d-xl-none'>
                    <i className="fas fa-times fs-5"></i>
                </span>
                <Link className="d-flex justify-content-center pt-4" to="/">
                    <NokhbaLogo style={{ width: "65%" }} className="side-bar" />
                    {/*<img src="/content/f-logo-no-white.png" style={{ width: "65%" }} />*/}
                    {/* <span className="ms-1 font-weight-bold text-white">Dar Alnokhba</span> */}
                </Link>
            </div>
            <hr className="horizontal light mt-0 mt-5 mb-2" />
            <div className="collapse navbar-collapse w-auto ps ps--active-x" id="sidenav-collapse-main">
                <ul className="navbar-nav jrtl">
                    <SideItem title={getText('dashboard')} to="/" iconclassName="fa-brands fa-dashcube" />
                    <SideItem title={getText('requests')} to="/requests" iconclassName="fa-solid fa-hand-holding-hand" />
                    <SideItem title={getText('persons')} to="/persons" iconclassName="fa-solid fa-users" />
                    <SideItem title={getText('filters')} to="/filters" iconclassName="fa-solid fa-filter" />
                    <SideItem title={getText('users')} to="/users" iconclassName="fa-solid fa-user-gear" />
                    <SideItem title={getText('logout')} to="/" onClick={e => setUser(null)} iconclassName="fa-solid fa-arrow-right-from-bracket" />
                </ul>
            </div>
        </aside>

        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ps ps--active-y">
            {/* <!-- Navbar --> */}
            <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none" id="navbarBlur" data-scroll="true">
                <div className="container-fluid py-1 px-3">
                    {/* <nav aria-label="breadcrumb">
                    <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                        <li className="breadcrumb-item text-sm">
                            <span className="opacity-5 text-dark">Admin</span>
                        </li>
                        <li className="breadcrumb-item text-sm text-dark active">Notifications</li>
                    </ol>
                    <h6 className="font-weight-bolder mb-0"></h6>
                </nav> */}
                    <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                        <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                        </div>
                        <ul className="navbar-nav w-100">
                            <li onClick={toggleSide} className="nav-item d-xl-none ps-3 d-flex align-items-center">
                                <a className="nav-link p-0 text-body">
                                    <div className="sidenav-toggler-inner">
                                        <i className="sidenav-toggler-line"></i>
                                        <i className="sidenav-toggler-line"></i>
                                        <i className="sidenav-toggler-line"></i>
                                    </div>
                                </a>
                            </li>
                            <li className="flex-grow-1"></li>
                            {/* <li className="nav-item d-flex align-items-center">
                            <span className="nav-link p-0 text-body">
                                <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer" aria-hidden="true"></i>
                            </span>
                        </li> */}
                            <li className="nav-item px-3 d-flex align-items-center" title='تغيير اللغة | Change language' onClick={() => setLng(lng === "ar" ? "en" : "ar")}>
                                <a className="nav-link p-0 text-body d-flex align-items-center cursor-pointer">
                                    <span className='mx-1'>{lng === "ar" ? "English" : "عربي"}</span>
                                    <i className="fa-solid fa-language fixed-plugin-button-nav cursor-pointer fs-5"></i>
                                </a>
                            </li>
                            {/* <li className="nav-item dropdown pe-2 d-flex align-items-center">
                            <a className="nav-link p-0 text-body" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-bell cursor-pointer" aria-hidden="true"></i>
                            </a>
                            <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
                                <li className="mb-2">
                                    <a className="dropdown-item border-radius-md" >
                                        <div className="d-flex py-1">
                                            <div className="my-auto">
                                                <img src="../assets/img/team-2.jpg" className="avatar avatar-sm  me-3 " />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="text-sm font-weight-normal mb-1">
                                                    <span className="font-weight-bold">New message</span> from Laur
                                                </h6>
                                                <p className="text-xs text-secondary mb-0">
                                                    <i className="fa fa-clock me-1" aria-hidden="true"></i>
                                                    13 minutes ago
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a className="dropdown-item border-radius-md" >
                                        <div className="d-flex py-1">
                                            <div className="my-auto">
                                                <img src="../assets/img/small-logos/logo-spotify.svg" className="avatar avatar-sm bg-gradient-dark  me-3 " />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="text-sm font-weight-normal mb-1">
                                                    <span className="font-weight-bold">New album</span> by Travis Scott
                                                </h6>
                                                <p className="text-xs text-secondary mb-0">
                                                    <i className="fa fa-clock me-1" aria-hidden="true"></i>
                                                    1 day
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item border-radius-md" >
                                        <div className="d-flex py-1">
                                            <div className="avatar avatar-sm bg-gradient-secondary  me-3  my-auto">
                                                <svg width="12px" height="12px" viewBox="0 0 43 36" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                    <title>credit-card</title>
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <g transform="translate(-2169.000000, -745.000000)" fill="#FFFFFF" fillRule="nonzero">
                                                            <g transform="translate(1716.000000, 291.000000)">
                                                                <g transform="translate(453.000000, 454.000000)">
                                                                    <path className="color-background" d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z" opacity="0.593633743"></path>
                                                                    <path className="color-background" d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="text-sm font-weight-normal mb-1">
                                                    Payment successfully completed
                                                </h6>
                                                <p className="text-xs text-secondary mb-0">
                                                    <i className="fa fa-clock me-1" aria-hidden="true"></i>
                                                    2 days
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
            {/* <!-- End Navbar --> */}
            <div className="container-fluid py-4">
                {children}
            </div>
        </main>

    </>
}

const SideItem = ({ title, iconclassName, to, setActive = true, ...etc }) => {
    const location = useLocation();
    return (
        <li className={`sidebar-item ${setActive && location.pathname == to ? 'active' : ''}`}>
            <Link className="sidebar-link" to={to} {...etc}>
                <i className={iconclassName}></i>
                <span>{title}</span>
            </Link>
        </li>
    )

}