import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./custom.css";
import ClientPage from "./pages/ClientPage";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { userState, loadingState, languageState } from "./Utlities/app-store";
import MainLayout from "./components/MainLayout";
import LoginPage from "./pages/LoginPage";
import ResetPage from "./pages/ResetPage";
import DashboardPage from './pages/DashboardPage';
import FiltersPage from './pages/FiltersPage';
import PersonsPage from './pages/PersonsPage';
import UsersPage from './pages/UsersPage';
import RequestsPage from './pages/RequestsPage';
import { consts } from "./Utlities/functions";
import JWTSetup from "./components/JWTSetup";

export default function App() {
  const user = useRecoilValue(userState);
  const [lng, setLng] = useRecoilState(languageState);
  // Language settings
  useEffect(() => {
    const lang = localStorage.getItem("ad-rec-lang");
    if (lang) {
      setLng(lang);
    } else {
      localStorage.setItem("ad-rec-lang", "ar")
    }
  }, [])
  useEffect(() => {
    if (lng === "ar") {
      document.body.classList.add("brtl")
      document.title = "المفاوض الدولي للإستقدام";
    } else {
      document.body.classList.remove("brtl")
      document.title = "International Negotiator Recruitment";
    }
    localStorage.setItem("ad-rec-lang", lng)
  }, [lng])
  // user settings
  useEffect(() => {
    if (!user) {
      delete consts.userData;
      consts.user = null;
    }
  }, [user]);
  if (!user) {
    return (
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/resetpassword" element={<ResetPage />} />
        <Route path="*" element={<ClientPage />} />
      </Routes>
    );
  }
  return <JWTSetup><Jen /></JWTSetup>;
}

const Jen = () => {
  // const setUser = useSetRecoilState(userState);
  // const [loaded, setLoaded] = useState(false);

  // // const showSpinner = useRecoilValue(loadingState);
  // useEffect(() => {
  //   setLoaded(user !== null);
  // }, []);
  // if (user !== null) return <LoadingSpinner />;
  return (
    <>
      <MainLayout>
        <Routes>
          <Route path="/filters" element={<FiltersPage />} />
          <Route path="/persons" element={<PersonsPage />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/requests" element={<RequestsPage />} />
          <Route path="*" element={<DashboardPage />} />
        </Routes>
      </MainLayout>
    </>
  );
};
