import { useFormikContext } from 'formik'
import React, { useMemo, useState } from 'react'

export default function FormTextArea({ propName, required, inputProps, containerClassName, title, type = "text", subText, children, ...etc }) {
    const { isSubmitting, values, handleChange, errors } = useFormikContext();
    const navigator = useMemo(() => propName ?? title, [])
    const invalid = useMemo(() => errors && errors[navigator], [errors])
    return (
        <div className={`form-group mb-3 ${(values[navigator] ? 'is-focused' : '')} ${invalid ? 'is-invalid' : values[navigator] ? 'is-valid' : ''}`}>
            <label className='form-label'>{title} {required && <span className='mx-1 text-danger'>*</span>}</label>
            <textarea type={type} autoComplete="new-password" className="form-control" disabled={isSubmitting} onChange={handleChange(navigator)} value={values[navigator] ?? ""}
                {...inputProps} {...etc}
            ></textarea>
            {invalid && <div className="invalid-feedback">
                {errors[navigator]}
            </div>}
            {children}
        </div>
    )
}