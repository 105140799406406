import SinglePageLayout from '../components/SinglePageLayout'
import React, { useMemo, useState } from 'react'
import FormInput from '../components/FormInput';
import * as Yup from "yup"
import { Formik } from "formik"
import { Link, useNavigate } from 'react-router-dom';
import $ from "jquery"
import { Hint } from "fnon"
import JustInput from '../components/JustInput';
import { getText } from '../Utlities/app-store';




export default function ResetPage() {
    const [pageState, setPageState] = useState(0);
    const [email, setEmail] = useState("");
    const [expires, setExpires] = useState("");
    const [code, setCode] = useState(null);
    const nav = useNavigate();
    const resetValidationSchema = useMemo(() => Yup.object({
        Password: Yup.string().required(getText("requiredf")).min(8, getText("minpassword")),
        PasswordConfirmation: Yup.string().required(getText("requiredf")).oneOf([Yup.ref('Password')], getText("confirmdoesnotmatch")),
    }), [])
    const sendCodeValidationSchemaSchema = useMemo(() => Yup.object({
        email: Yup.string().email(getText('invalidemail')).required(getText("requiredf"))
    }), [])
    return (
        <SinglePageLayout title={getText("resetpassword")}>
            {pageState === 0 ? <>
                <div className="card-body">
                    <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        initialValues={{ email: "" }}
                        validationSchema={sendCodeValidationSchemaSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            $.ajax(`/api/manage/resetpassword?email=${values.email}`).then(({ email, expires }) => {
                                setEmail(email)
                                setExpires(expires);
                                setPageState(2);
                            }).catch(() => {
                                setPageState(1);
                            }).always(() => {
                                setSubmitting(false)
                            })
                        }}
                    >
                        {({ handleSubmit, isSubmitting }) => (

                            <form method="POST" className='row' onSubmit={handleSubmit} noValidate>
                                <p className="text-muted text-center">{getText("enteryouremail")}</p>
                                <div className="form-group">
                                    <FormInput propName="email" placeholder={getText("email")} type="email" iconLeft={<i className="bi bi-person"></i>} className="form-control-xl" />
                                </div>
                                <div className="form-group">
                                    <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-block btn-lg shadow-lg mt-5">
                                        {getText("resetpassword")}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                    <p className="mt-4 text-sm text-center">
                        {getText("irememberedpassword")}!
                        <Link to="/login" className="text-primary font-weight-bold mx-1">{getText("login")}</Link>
                    </p>
                </div>
            </> :
                pageState === 1 ? <div className="p-5">
                    <p className="text-muted text-center mb-5">{getText('emailsenttoresetpass')}</p><div className="text-center"><Link to="/login" className='btn btn-primary w-100 my-4 mb-2'>{getText('login')}</Link></div>

                </div> :
                    <>
                        {code === null ?
                            <ResetCodeTimer callback={c => {
                                setCode(c)
                            }} email={email} expires={expires} /> :
                            <>
                                <div className="card-body">
                                    <p className="text-muted text-center">{getText('selectnewpass')}</p>
                                    <Formik
                                        initialValues={{ Password: "", PasswordConfirmation: "" }}
                                        validationSchema={resetValidationSchema}
                                        // validateOnBlur={false}
                                        // validateOnChange={false}
                                        onSubmit={(values, { setSubmitting, resetForm }) => {
                                            const vals = { ...values, token: code, email }
                                            $.ajax({
                                                url: "/api/manage/resetpassword",
                                                data: vals,
                                                method: "POST"
                                            }).then(() => {
                                                Hint.Success(getText('passwordupdated'))
                                                nav("/login");
                                            }).always(() => {
                                                resetForm()
                                                setSubmitting(false);
                                            })
                                            // setTimeout(() => {
                                            //     setSubmitting(false)
                                            // }, 3000)
                                        }}
                                    >
                                        {({ handleSubmit, isSubmitting }) => (
                                            <form noValidate onSubmit={handleSubmit}>
                                                <div>
                                                    <FormInput title={getText('password')} propName="Password" type="password" />
                                                </div>
                                                <div>
                                                    <FormInput title={getText('confirmpassword')} propName="PasswordConfirmation" type="password" />
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" disabled={isSubmitting} className="btn btn-warning w-100 my-4 mb-2">
                                                        {getText('changepassword')}
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </>}
                    </>}
        </SinglePageLayout>
    )
}
const min5 = 1000 * 5 * 60;
const ResetCodeTimer = ({ callback, email, expires }) => {
    const [code, setCode] = React.useState("");
    // const [time, setTime] = React.useState(Date.now() + 10000);
    const [expired, setExpired] = React.useState(false);
    const [isChecking, setIsChecking] = React.useState(false);
    const [expireTime, setExpireTime] = useState(expires);
    const expTime = useMemo(() => {
        if (expireTime) return new Date(expireTime);
        return new Date(new Date() + min5);
    }, [expireTime])
    // const [restart, setRestart] = React.useState(0);
    // const renderer = ({ hours, minutes, seconds, completed }) => {
    //   if (completed || expired) {
    //     setExpired(true);
    //     // Render a completed state
    //     return <p className="text-danger text-center">إنتهت وقت صلاحية الكود<br />00:00:00</p>;
    //   } else {
    //     // Render a countdown
    //     return <div className='text-center'><span><>سينتهي الكود خلال</></span><p><span>{hours.toString().padStart(2, "0")}:{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}</span></p></div>;
    //   }
    // };
    // const expTime = useMemo(() => Date.now() + (min5), [restart])
    const handleCheckCode = () => {
        if (expired) {
            setIsChecking(true);
            $.ajax({
                url: `/api/manage/resetpassword?email=${email}`
            }).always(() => {
                setIsChecking(false);
                setExpireTime(new Date(new Date() + min5))
                // setRestart(restart + 1)
                // setExpired(false)
            })
        } else if (code && code.length === 5) {
            setIsChecking(true);
            $.ajax({
                url: "/api/manage/checkcode",
                method: "POST",
                data: {
                    email, code
                }
            }).then(({ token }) => {
                callback(token);
            }).catch(() => {
                Hint.Danger(getText('codenotmatch'), {
                    position: "center-top",
                    animation: "slide-top"
                })
            }).always(() => setIsChecking(false));
        }
    }
    return (
        <div className="card-body row">
            <p className="text-muted text-center">
                {getText('resetcodesent')}</p>
            <div>
                {!expired && <div className="form-group">
                    <JustInput title={getText("typecodehere")} value={code} onChange={e => {
                        const str = e.target.value ?? "";
                        setCode(str.substring(0, 5))
                    }} type="number" minLength={5} maxLength={5} disabled={isChecking} style={{ textAlign: "center" }} />
                    {/* <input value={code} onChange={e => {
                        const str = e.target.value ?? "";
                        setCode(str.substring(0, 5))
                    }} type="number" minLength={5} maxLength={5} className={`form-control text-center no-spinner ${code?.length !== 5 ? " invalid" : ""}`} disabled={isChecking} /> */}
                </div>}
                <p className='text-center text-danger'>{getText('codeexpires')}</p>
                {/* <Countdown
          key={restart}
          date={new Date() - (expires ?? expTime)}
          renderer={renderer}
        /> */}
                <div className="form-group">
                    <button onClick={handleCheckCode} disabled={isChecking} type="submit" className={`btn btn-${expired ? "danger" : "primary"} w-100 my-4 mb-2`}>
                        {getText(expired ? "requestnewcode" : 'checkcode')}
                    </button>
                </div>
            </div>
        </div>
    )
}