import React, { useState, useMemo } from 'react'
import { getRapidTheme, getRapidColor } from '../Utlities/functions';
import FilterItemsPage from './FilterItemsPage';
import { getText } from '../Utlities/app-store';


const fs = [
    { title: "countries", iconClass: "fa-solid fa-earth-africa" },
    { title: "jobs", iconClass: "fa-solid fa-screwdriver-wrench" },
    { title: "categories", iconClass: "fa-solid fa-lines-leaning" },
    { title: "experiances", iconClass: "fa-solid fa-kitchen-set" },
    { title: "religions", iconClass: "fa-solid fa-mosque" },
    { title: "statuses", iconClass: "fa-solid fa-signal" },
    //Statuses
];

export default function FiltersPage() {
    const [current, setCurrent] = useState(null);
    return (
        <>
            <div className="row my-3">
                {fs.map((item, index) => <Card key={item.title} {...item} theme={getRapidTheme(index)} index={index} onClick={() => setCurrent(index)} className={(current === index ? "disabled" : "")} />)}
            </div>
            {current !== null && <FilterItemsPage Type={current} />}
        </>
    )
}

const Card = ({ iconClass, theme = "primary", title, className = "", ...etc }) => {

    return (
        <div className="col-6 col-lg-3 col-md-6">
            <div className={`card hover-effect scale ${className}`} {...etc}>
                <div className="card-body px-4 py-4-5">
                    <div className="row">
                        <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start ">
                            <div className={`stats-icon text-${theme} mb-2`}>
                                <i className={iconClass}></i>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                            <h6 className="font-semibold">{getText(title)}</h6>
                            <span className="mb-0 small text-muted">{getText('manage')} {getText(title)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}