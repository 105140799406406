import { Formik } from 'formik'
import React, { useMemo } from 'react'
import * as Yup from "yup"
import FormInput from '../components/FormInput'
import { Link, useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { getText, userState } from './../Utlities/app-store';
import $ from "jquery"
import { Hint, Ask } from "fnon"
import SinglePageLayout from '../components/SinglePageLayout'
import { consts } from './../Utlities/functions';



export default function LoginPage() {
    const setUser = useSetRecoilState(userState);
    const nav = useNavigate();
    const validationSchema = useMemo(() => Yup.object({
        username: Yup.string().required(getText('requiredf')).email(getText('invalidvalue')),
        password: Yup.string().min(8, getText('minpassword')).required(getText('requiredf'))
    }), [])
    //username: "adel@email.com", password: "12345678"
    return (
        <SinglePageLayout title={getText("signin")}>
            <Formik
                validationSchema={validationSchema}
                initialValues={{ username: "", password: "" }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    $.ajax({
                        url: "/api/login/signin",
                        data: values,
                        headers: { 'x-gs-web': 'AdelCo' }
                    }).then(data => {
                        if (!data.EmailConfirmed || data.LockoutEnabled) {
                            Hint.Danger(getText(data.LockoutEnabled ? "lockedaccount" : "accountnotactive"), { position: "center-top", animation: "slide-top" })
                            resetForm();
                            return;
                        }
                        consts.user = data;
                        setUser(data);
                        nav("/")

                    }).catch(error => {
                        Hint.Danger(getText('noaccountmatch'), { position: "center-top", animation: "slide-top" })
                    }).always(() => {
                        setSubmitting(false);
                    })
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <form role="form" className="py-3 row rtl" noValidate onSubmit={handleSubmit}>
                        <div>
                            <FormInput propName="username" placeholder={getText("email")} type="email" iconLeft={<i className="bi bi-person"></i>} className="form-control-xl" />
                        </div>
                        <div>
                            <FormInput propName="password" placeholder={getText("password")} type="password" iconLeft={<i className="bi bi-shield-lock"></i>} className="form-control-xl" />
                        </div>
                        <button class="btn btn-primary btn-block btn-lg shadow-lg mt-5" disabled={isSubmitting}>{getText("enter")}</button>
                    </form>
                )}
            </Formik>
            <div class="text-center mt-5 text-lg ">
                <p class="text-gray-600">{getText("forgetpassword")}? <Link to="/resetpassword" className="font-bold">{getText("resetpassword")}</Link>.</p>
            </div>
        </SinglePageLayout>

    )
}
